import {
  ApolloClient,
  ApolloLink,
  DefaultContext,
  FieldReadFunction,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { RetryLink } from "@apollo/client/link/retry/retryLink";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import {
  getMainDefinition,
  relayStylePagination,
} from "@apollo/client/utilities";
import {
  GraphQLErrorCode,
  TRACE_ID_HEADER,
  getPersistedQueryHash,
  getProductionEnvironment,
  sleep,
} from "@hex/common";
import { createUploadLink } from "apollo-upload-client";
import { Client, createClient as createWsClient } from "graphql-ws";
import { uniq } from "lodash";

import introspectionQueryResultData from "../generated/fragmentTypes";
import hashes from "../generated/queryNameToHash.json";
import { refreshProductVersionStatus } from "../hooks/useProductVersionStatus";
import {
  ProductVersionsDocument,
  ProductVersionsQuery,
} from "../hooks/useProductVersionStatus.generated";

import { safeSessionStorage } from "./browserStorage.js";
import { CONNECTION_LOG } from "./ConnectionLog";
import { ConnectionStatus } from "./ConnectionStatus";
import {
  autoReloadClientOnAuthFail,
  webClientVersion,
  websocketKeepAliveSeconds,
} from "./data";
import { createRequestHeaders } from "./headers";
import { logErrorMsg, logInfoMsg } from "./logging.js";
import { TRACE_LOG } from "./TraceLog";
import { subscribeToFlag } from "./useHexFlags";

function extractVersionNumber(version: string | undefined): string | undefined {
  if (version === undefined) return undefined;
  const match = version.match(/^\d+.\d+.\d+/g);
  return match && match.length > 0 ? match[0] : undefined;
}

const ONE_HOUR = 60 * 60 * 1000;
const VERSION_RELOAD_KEY = "lastVersionMismatchReload";

let refreshOnGraphQlErrorEnabled = false;
subscribeToFlag("refresh-on-graphql-error", (value) => {
  if (typeof value === "boolean") {
    refreshOnGraphQlErrorEnabled = value;
  }
});

export interface CreateApolloClientParams {
  /**
   * Called when the websocket connection status changes
   */
  onConnectionStatusChange?: (newStatus: ConnectionStatus) => void;
  /**
   * Called when the websocket client is created
   */
  onWsClientCreated?: (wsClient: Client) => void;
  /** Called when the websocket sends a keepalive "ping" */
  onWsPing?: () => void;
  /** Called when the websocket sends a keepalive "pong" */
  onWsPong?: () => void;
}

// eslint-disable-next-line max-lines-per-function -- this function is pretty hard to make shorter
export function createApolloClient({
  onConnectionStatusChange,
  onWsClientCreated,
  onWsPing,
  onWsPong,
}: CreateApolloClientParams = {}): ApolloClient<unknown> {
  let client: ApolloClient<unknown> | undefined = undefined;
  // Create a WebSocket link:
  const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";

  const APOLLO_RETRY_MAX_ATTEMPTS = 6;
  const APOLLO_RETRY_DELAY_MS = 500;

  const timeToWaitForPong = 15_000;
  let activeSocket: WebSocket, timedOut: NodeJS.Timeout; // use these for ping/pong events and to detect if the server should close.
  // in order to log when the server has taken too long to respond, we can check if the timeout after the latest 'ping' has been met.

  // see: https://hex-tech-hq.slack.com/archives/C05KVHYEQMN/p1706503412202059?thread_ts=1706300979.171349&cid=C05KVHYEQMN
  // this value is returned data.ts, which is configured from the server as an env variable.
  // The previous and default timeout we use is 1 minute, which leaves a healthy buffer for network flakes, however we have a hypothesis
  // that decreasing this time will improve multiplayer pains.
  const parsedWebsocketKeepAliveInSeconds = websocketKeepAliveSeconds
    ? parseInt(websocketKeepAliveSeconds, 10)
    : 60;

  const wsClient = createWsClient({
    url: `${wsProtocol}://${window.location.host}/subscriptions`,
    connectionParams: () => {
      const headers = createRequestHeaders();
      return {
        headers,
      };
    },
    // Establishing a WS connection immediately helps to avoid race conditions (see SUP-36)
    lazy: false,
    // should always retry for any non truly fatal errors
    // see https://the-guild.dev/graphql/ws/docs/interfaces/client.ClientOptions#retryattempts
    // for error types that are not retried regardless of this setting
    shouldRetry: () => {
      return true;
    },
    retryAttempts: Infinity,
    retryWait: async (retries) => {
      let retryDelay = 1000;

      // for the first 4 retries, back off exponentially
      // this ends up being roughly every 15 seconds
      for (let i = 0; i < Math.min(4, retries); i++) {
        retryDelay *= 2;
      }

      const retryJitter = Math.random() * (1000 - 100) + 100;

      return await sleep(retryDelay + retryJitter);
    },
    keepAlive: 1000 * parsedWebsocketKeepAliveInSeconds,
    on: {
      ping: () => {
        onWsPing?.();
        timedOut = setTimeout(() => {
          // If we have an active socket that sends a ping from the client to the server every keepAlive seconds, and has not received a 'pong' event after timeToWaitForPong ms,
          // then we should alert / log that the server is taking too long to respond.
          // This could indicate that the connection is no longer operable or open.
          // TODO: We are just logging this now for visibility, but because nothing happens when a ping event does not receive its respective pong, consider closing
          // the connection so it can be re-established. https://github.com/enisdenjo/graphql-ws/blob/e2603be5788e51fc5ce5649a508f2649ee6adb27/src/client.ts#L273
          if (activeSocket?.readyState === WebSocket.OPEN)
            CONNECTION_LOG.push({
              status: ConnectionStatus.DEGRADED_CONNECTION,
              time: new Date(),
            });
        }, timeToWaitForPong); // wait 15 seconds for the pong and then log if there was no pong event.
      },
      pong: () => {
        onWsPong?.();
        clearTimeout(timedOut); // pong is received, clear connection close timeout
      },
      connecting: () => {
        onConnectionStatusChange?.(ConnectionStatus.CONNECTING);
      },
      connected: (socket) => {
        // We know that the parameter for connected will be a WebSocket. Casting
        // to unknown first to avoid a type error.
        activeSocket = socket as unknown as WebSocket;
        if (client) {
          refreshProductVersionStatus(client);
        }
        onConnectionStatusChange?.(ConnectionStatus.CONNECTED);
      },
      closed: () => {
        onConnectionStatusChange?.(ConnectionStatus.DISCONNECTED);
      },
    },
  });
  onWsClientCreated?.(wsClient);

  const wsLink = new GraphQLWsLink(wsClient);

  const headerLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...createRequestHeaders(),
        ...headers,
      },
    };
  });

  const persistedLink = createPersistedQueryLink({
    generateHash: getPersistedQueryHash(hashes),
    // override the disable logic since we very very rarely want to completly
    // stop using hashes
    disable: ({ graphQLErrors }) => {
      if (
        graphQLErrors?.find(
          (e) =>
            e.extensions?.code ===
            GraphQLErrorCode.PERSISTED_QUERY_NOT_SUPPORTED,
        ) != null
      ) {
        return true;
      }
      return false;
    },
  });

  type NetworkRetryContext = { retryCount?: number };

  const networkRetryLogLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      const context: DefaultContext & NetworkRetryContext =
        operation.getContext();
      if (context.retryCount != null) {
        if (context.retryCount < APOLLO_RETRY_MAX_ATTEMPTS) {
          const traceId = context.headers?.[TRACE_ID_HEADER] ?? "<unknown>";
          logInfoMsg(
            `[Apollo Network Retry]: success after retrying - attempts: ${context.retryCount}, op: ${operation.operationName}, trace id: ${traceId}`,
            {
              safe: {
                hexTraceId: traceId,
                operationName: operation.operationName,
                retryCount: context.retryCount,
              },
            },
          );
        } else {
          // We will never hit this, because the retry link terminates the link chain after the max attempts are reached
        }
      }
      return data;
    });
  });

  /**
   * https://www.apollographql.com/docs/react/api/link/apollo-link-retry
   * Note: It does not currently handle retries for GraphQL errors in the response,
   * only for network errors (which is what we want!)
   */
  const retryLink = new RetryLink({
    attempts: (count, operation, err) => {
      // Don't retry on requests that were intentionally aborted
      if (err instanceof DOMException && err.name === "AbortError") {
        return false;
      }

      const additionalContext: NetworkRetryContext = { retryCount: count };

      operation.setContext(additionalContext);

      if (count === APOLLO_RETRY_MAX_ATTEMPTS) {
        const traceId =
          operation.getContext().headers?.[TRACE_ID_HEADER] ?? "<unknown>";
        const message = `[Apollo Network Retry]: max attempts reached`;
        logErrorMsg(
          new Error(message),
          `${message} - attempts: ${count}, op: ${operation.operationName}, trace id: ${traceId}`,
          {
            safe: {
              hexTraceId: traceId,
              maxAttempts: APOLLO_RETRY_MAX_ATTEMPTS,
            },
          },
        );
      }
      if (
        autoReloadClientOnAuthFail &&
        operation.getContext().response.status === 401
      ) {
        // don't reload if last reload was <5 seconds ago to prevent reload loops
        const lastReload = safeSessionStorage.getItem("lastReload");
        if (
          (safeSessionStorage.enabled && lastReload == null) ||
          (lastReload != null &&
            new Date().valueOf() - parseInt(lastReload) > 5000)
        ) {
          const message = "Apollo client received a 401";
          logErrorMsg(new Error(message), "[Authentication error]");
          safeSessionStorage.setItem(
            "lastReload",
            new Date().valueOf().toString(),
          );
          window.location.reload();
        }
      }

      return count < APOLLO_RETRY_MAX_ATTEMPTS;
    },
    delay: {
      initial: APOLLO_RETRY_DELAY_MS,
      max: Infinity,
      jitter: true,
    },
  });

  const currentEnvironment = getProductionEnvironment(window.location.hostname);

  // Make sure that by default errors are printed out with a trace id in the console
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const traceId: string =
      operation.getContext().response?.headers?.get(TRACE_ID_HEADER) ??
      "<unknown>";
    // Network errors are retried in the retryLink.
    if (networkError) {
      logErrorMsg(networkError, `[Network error]: ${networkError}`, {
        safe: {
          hexTraceId: traceId,
        },
      });
    }
    if (traceId != null) {
      graphQLErrors?.forEach((e) => {
        if (
          e.extensions == null ||
          e.extensions.code !== GraphQLErrorCode.USER_FACING_ERROR
        ) {
          e.message = `${e.message} - trace id: '${traceId}'`;
        }

        if (
          autoReloadClientOnAuthFail &&
          e.extensions != null &&
          e.extensions.code === GraphQLErrorCode.UNAUTHENTICATED &&
          !window.location.pathname.endsWith("login")
        ) {
          // don't reload if last reload was <5 seconds ago to prevent reload loops
          const lastReload = safeSessionStorage.getItem("lastReload");
          if (
            (safeSessionStorage.enabled && lastReload == null) ||
            (lastReload != null &&
              new Date().valueOf() - parseInt(lastReload) > 5000)
          ) {
            logErrorMsg(e, "[Authentication error] " + operation.operationName);
            safeSessionStorage.setItem(
              "lastReload",
              new Date().valueOf().toString(),
            );
            window.location.reload();
          }
        }

        // reload the browser if client is out of date
        if (
          currentEnvironment !== "local" &&
          refreshOnGraphQlErrorEnabled &&
          e.extensions?.code !== GraphQLErrorCode.UNAUTHENTICATED
        ) {
          /*
           * Don't reload if last reload was < 1 hour ago to prevent reload loops.
           *
           * It is an unlikely situation, but if there is a build/deploy/edge issue in
           * which the hosted client bundle has a different version than that returned
           * by the server API, we could have endless browser reloads without this check.
           */
          const lastReload = safeSessionStorage.getItem(VERSION_RELOAD_KEY);
          if (
            (safeSessionStorage.enabled && lastReload == null) ||
            (lastReload != null &&
              new Date().valueOf() - parseInt(lastReload) > ONE_HOUR)
          ) {
            // get the app version from the server (pull it out of Apollo cache)
            // app version is loaded into cache due to HexRoutes usage of useProjectVersionCheck()
            const ctx = operation.getContext();
            const cache: InMemoryCache = ctx.cache;
            const versions = cache.readQuery<ProductVersionsQuery>({
              query: ProductVersionsDocument,
            });
            const localVersion = extractVersionNumber(webClientVersion);
            const serverVersion = extractVersionNumber(
              versions?.productVersions.appVersion,
            );

            // if client and server app versions don't match, reload the browser
            if (
              localVersion &&
              serverVersion &&
              localVersion !== serverVersion
            ) {
              safeSessionStorage.setItem(
                VERSION_RELOAD_KEY,
                new Date().valueOf().toString(),
              );
              logInfoMsg("Reloading client due to GraphQL error", {
                safe: {
                  localVersion,
                  serverVersion,
                },
              });
              window.location.reload();
            }
          }
        }
      });
    }
  });

  const traceLogLink = new ApolloLink((operation, forward) => {
    const traceId: string =
      operation.getContext().headers?.[TRACE_ID_HEADER] ?? "<unknown>";
    TRACE_LOG.push({
      traceId,
      status: "STARTING",
      name: operation.operationName,
    });
    return forward(operation).map((data) => {
      TRACE_LOG.push({
        traceId,
        status: data.errors == null ? "OK" : "ERROR",
        name: operation.operationName,
      });
      return data;
    });
  });

  // Note: this link doubles as our HTTP link
  const uploadLink = createUploadLink({
    uri: (op) => {
      let extra: string | null = null;
      if ("requests" in op.variables) {
        extra = uniq(
          op.variables["requests"]?.map((r: any) => r?.operation?.type),
        )
          .slice(0, 5)
          .join(",");
      }
      return `/graphql?op=${op.operationName}${
        extra != null ? `&extra=${extra}` : ""
      }`;
    },
  }) as unknown as ApolloLink;
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    // For subscriptions
    ApolloLink.from([networkRetryLogLink, retryLink, persistedLink, wsLink]),
    // For all other Apollo client requests (normal HTTP)
    // the ordering of chaining links will go:
    // .from([a, b, c]): c called, then b, then a
    ApolloLink.from([
      networkRetryLogLink,
      retryLink,
      errorLink,
      headerLink,
      persistedLink,
      traceLogLink,
      uploadLink,
    ]),
  );

  const cache = new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    typePolicies: {
      AppSession: {
        fields: {
          scope: {
            merge: false,
          },
        },
      },
      Group: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      IntrinsicGroup: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      User: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
        fields: {
          notifications: relayStylePagination(),
        },
      },
      HexVersionAOLog: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      Parameter: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      DisplayTableCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      MarkdownCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      MetricCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      CodeCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      SqlCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      VegaChartCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      TextCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      MapCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      WritebackCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      DbtMetricCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      PivotCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      FilterCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      ComponentImportCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      ChartCell: {
        // Can remove once https://github.com/apollographql/apollo-client/issues/10599 is fixed
        keyFields: ["id"],
      },
      Hex: {
        fields: {
          version: {
            merge: false,
          },
          permissions: {
            merge: false,
          },
          scheduledRuns: relayStylePagination(["runType"]),
        },
      },
      Org: {
        fields: {
          groupsV2: relayStylePagination(["searchTerm", "order"]),
          usersV2: relayStylePagination([
            "searchTerm",
            "runType",
            "orgRoleFilter",
            "orderBy",
            "activeFilter",
          ]),
        },
      },
      DataSourceDatabase: {
        fields: {
          schemasPaginated: relayStylePagination(),
        },
      },
      DataSourceSchema: {
        fields: {
          tables: relayStylePagination(),
        },
      },
      HexVersion: {
        fields: {
          secrets: {
            merge: false,
          },
          hexVersionSecrets: {
            merge: false,
          },
          history: relayStylePagination([
            "order",
            "operationFilter",
            "eventFilter",
            "versionFilter",
          ]),
        },
      },
      Mutation: {
        fields: {
          acquireLock: {
            merge: false,
          },
        },
      },
      Subscription: {
        fields: {
          uiLockUpdateForHexVersion: {
            merge: false,
          },
        },
      },
      DataConnection: {
        fields: {
          activeHexes: relayStylePagination([]),
        },
      },
      ExternalFileIntegration: {
        fields: {
          activeHexes: relayStylePagination([]),
        },
      },
      KernelSourceImage: {
        fields: {
          activeHexes: relayStylePagination([]),
        },
      },
      Secret: {
        fields: {
          activeHexes: relayStylePagination([]),
        },
      },
      SyncRepository: {
        fields: {
          activeHexes: relayStylePagination([]),
        },
      },
      VcsPackage: {
        fields: {
          activeHexes: relayStylePagination([]),
        },
      },
      Query: {
        fields: {
          allOrgs: relayStylePagination(["searchTerm"]),
          collections: relayStylePagination([
            "searchTerm",
            "ownershipLevel",
            "onlyStarred",
            "order",
          ]),
          collectionHexLinks: relayStylePagination(["collectionId", "order"]),
          domainDenials: relayStylePagination(["searchTerm", "isSingleTenant"]),
          emailDenials: relayStylePagination(["searchTerm"]),
          hexSchedules: relayStylePagination([
            "searchTerm",
            "onlyEnabled",
            "orgId",
            "order",
            "latestRunStatusFilter",
            "enabledFilter",
            "cadenceFilter",
          ]),
          hexes: relayStylePagination([
            "ownershipLevel",
            "onlyPublished",
            "onlyStarred",
            "onlyViewed",
            "order",
            "categories",
            "statuses",
            "searchTerm",
            "trashed",
            "hexIds",
            "components",
            "archived",
            "sortDirection",
            "includeCreatorImgUrl",
          ]),
          safeOrUnknownHexes: relayStylePagination([
            "ownershipLevel",
            "onlyPublished",
            "onlyStarred",
            "onlyViewed",
            "order",
            "categories",
            "statuses",
            "searchTerm",
            "trashed",
            "hexIds",
            "components",
            "archived",
            "sortDirection",
            "includeCreatorImgUrl",
          ]),
          hexVersionHistory: relayStylePagination([
            "hexVersionId",
            "order",
            "operationFilter",
            "eventFilter",
            "versionFilter",
          ]),
          quickSearchHexes: relayStylePagination([
            "searchQuery",
            "orgId",
            "fields",
            "createdDate",
            "userUpdatedDate",
            "categories",
            "statuses",
            "creators",
            "archived",
          ]),
          getTagsForDockerImageRepository: relayStylePagination([
            "dockerImageRepositoryId",
          ]),
          magicEvents: relayStylePagination(["orgId"]),
          externalFileIntegrationAvailableFiles: relayStylePagination([
            "integrationId",
            "prefix",
          ]),
          telemetries: relayStylePagination(["searchEmail"]),
          telemetryDenials: relayStylePagination(["searchString"]),
          secrets: {
            merge: false,
          },
          productVersions: {
            merge: false,
          },
          locksForHexVersion: {
            merge: false,
          },
          appSessionById: {
            read: byIdReadFieldPolicy({
              idArgName: "appSessionId",
              typename: "AppSession",
            }),
          },
          appSessionCellById: {
            read: byIdReadFieldPolicy({
              idArgName: "appSessionCellId",
              typename: "AppSessionCell",
            }),
          },
          collectionById: {
            read: byIdReadFieldPolicy({
              idArgName: "collectionId",
              typename: "Collection",
            }),
          },
          dataConnectionById: {
            read: byIdReadFieldPolicy({
              idArgName: "dataConnectionId",
              typename: "DataConnection",
            }),
          },
          dataConnectionHexVersionLinkById: {
            read: byIdReadFieldPolicy({
              idArgName: "dataConnectionHexVersionLinkId",
              typename: "DataConnectionHexVersionLink",
            }),
          },
          dataSourceColumnById: {
            read: byIdReadFieldPolicy({
              idArgName: "dataSourceColumnId",
              typename: "DataSourceColumn",
            }),
          },
          dataSourceDatabaseById: {
            read: byIdReadFieldPolicy({
              idArgName: "dataSourceDatabaseId",
              typename: "DataSourceDatabase",
            }),
          },
          dataSourceSchemaById: {
            read: byIdReadFieldPolicy({
              idArgName: "dataSourceSchemaId",
              typename: "DataSourceSchema",
            }),
          },
          dataSourceTableById: {
            read: byIdReadFieldPolicy({
              idArgName: "dataSourceTableId",
              typename: "DataSourceTable",
            }),
          },
          dbtMetricCellById: {
            read: byIdReadFieldPolicy({
              idArgName: "dbtMetricCellId",
              typename: "DbtMetricCell",
            }),
          },
          dockerImageRepositoryById: {
            read: byIdReadFieldPolicy({
              idArgName: "dockerImageRepositoryId",
              typename: "DockerImageRepository",
            }),
          },
          externalFileIntegrationHexVersionLinkById: {
            read: byIdReadFieldPolicy({
              idArgName: "externalFileIntegrationHexVersionLinkId",
              typename: "ExternalFileIntegrationHexVersionLink",
            }),
          },
          fileHexVersionLinkById: {
            read: byIdReadFieldPolicy({
              idArgName: "fileHexVersionLinkId",
              typename: "FileHexVersionLink",
            }),
          },
          getSecretById: {
            read: byIdReadFieldPolicy({
              idArgName: "secretId",
              typename: "Secret",
            }),
          },
          groupById: {
            read: byIdReadFieldPolicy({
              idArgName: "groupId",
              typename: "Group",
            }),
          },
          hexById: {
            read: byIdReadFieldPolicy({
              idArgName: "hexId",
              typename: "Hex",
            }),
          },
          hexVersionById: {
            read: byIdReadFieldPolicy({
              idArgName: "hexVersionId",
              typename: "HexVersion",
            }),
          },
          kernelSourceImageById: {
            read: byIdReadFieldPolicy({
              idArgName: "kernelSourceImageId",
              typename: "KernelSourceImage",
            }),
          },
          kernelSourceImageVersionById: {
            read: byIdReadFieldPolicy({
              idArgName: "kernelSourceImageVersionId",
              typename: "KernelSourceImageVersion",
            }),
          },
          orgById: {
            read: byIdReadFieldPolicy({
              idArgName: "orgId",
              typename: "Org",
            }),
          },
          reviewRequestById: {
            read: byIdReadFieldPolicy({
              idArgName: "reviewRequestId",
              typename: "ReviewRequest",
            }),
          },
          syncRepositoryById: {
            read: byIdReadFieldPolicy({
              idArgName: "syncRepositoryId",
              typename: "SyncRepository",
            }),
          },
          syncRepositoryHexVersionLinkById: {
            read: byIdReadFieldPolicy({
              idArgName: "syncRepositoryHexVersionLinkId",
              typename: "SyncRepositoryHexVersionLink",
            }),
          },
          userById: {
            read: byIdReadFieldPolicy({
              idArgName: "userId",
              typename: "User",
            }),
          },
          vcsPackageHexVersionLinkById: {
            read: byIdReadFieldPolicy({
              idArgName: "vcsPackageHexVersionLinkId",
              typename: "VcsPackageHexVersionLinkId",
            }),
          },
        },
      },
    },
  });

  client = new ApolloClient({
    assumeImmutableResults: true,
    link: link,
    cache: cache,
    connectToDevTools: process.env.NODE_ENV !== "production",
    typeDefs: [],
  });
  return client;
}

/**
 * Allows us to do a cache redirect for query that
 * is looking up an entity by id.
 */
function byIdReadFieldPolicy({
  idArgName,
  typename,
}: {
  idArgName: string;
  typename: string;
}): FieldReadFunction<unknown> {
  return (existing, { args, toReference }) => {
    if (existing != null) {
      return existing;
    }
    if (args == null) {
      return null;
    }

    // the 'read' function does not accept a type parameters for args so we can't have
    // typesafety here
    const id = args[idArgName];
    if (id == null) {
      return null;
    }

    return toReference({
      __typename: typename,
      id,
    });
  };
}
